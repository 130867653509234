@import '../../assets/sass/styles.scss';

.Register {
  margin: 5rem;
  .container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    h2 {
      font-size: 2.5rem;
      font-weight: 500;
      margin-bottom: 20px;
      color: $primary;
    }
    #register-form {
      .help {
        font-size: 0.9rem;
        font-weight: bold;
      }
      .field {
        min-width: 500px;
        input {
          border: 2px solid $primary;
          background-color: transparent;
          font-size: 1.2rem;
          &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $primary-transparent;
            opacity: 1; /* Firefox */
          }

          &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $primary-transparent;
          }

          &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: $primary-transparent;
          }
        }
        .checkbox {
          input {
            margin-right: 5px;
          }
        }
      }
      .submit-btn-wrapper {
        text-align: center;

        .button {
          margin: 20px 0;
        }
        .register-notice {
          font-size: 1.2rem;
        }
      }
    }
  }
  .campaign-over-wrapper {
    text-align: center;
    font-size: 1.5rem;
    span {
      display: block;
    }
  }
}

@media screen and (max-width: 769px) {
  .Register {
    margin: 3rem 0;
    .container {
      h2 {
        font-size: 1.4rem;
      }
      #register-form {
        .field {
          min-width: 90vw;
        }
        .submit-btn-wrapper {
          .register-notice {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}
