@import '../../assets/sass/styles.scss';
#winners-modal {
  .modal-content {
    min-width: 50vw;
    background-color: $background-color;
    padding: 20px;
    border-radius: 5px;
    h2 {
      color: $primary;
      font-size: 2.5rem;
      font-weight: 600;
      text-align: center;
      padding-bottom: 30px;
    }
    .winners-wrapper {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: 0;
      margin-right: 0;
      min-height: 40vh;
      .column {
        padding: 0;
        text-align: center;
        font-size: 1.2rem;
        .prize-name {
          font-size: 1.6rem;
          font-weight: 600;
        }
      }
    }
    .close-btn-wrapper {
      text-align: right;
    }
  }
}

@media screen and (max-width: 769px) {
  #winners-modal {
    .modal-content {
      .winners-wrapper {
        .column {
          padding-bottom: 20px;
        }
      }
    }
  }
}
