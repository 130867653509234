@import './assets/sass/styles.scss';

.App {
  button.button,
  a.button {
    background-color: $button-bg-color;
    border: none;
    padding: 0 45px;
    color: $secondary;
    border-radius: 10px;
    font-size: 1.4rem;
    font-weight: 600;
    cursor: pointer;
    height: auto;
    &:hover {
      background-color: $button-bg-hover-color;
    }
  }
  .cookie-container {
    position: absolute;
    bottom: 0;
    z-index: 999;
    width: 100vw;
    #teconsent {
      position: fixed;
      bottom: 0;
    }
  }
}

@media screen and (max-width: 769px) {
  .App {
    .cookie-container {
      position: relative;
      #teconsent {
        max-width: 30vw;
      }
    }
  }
}
