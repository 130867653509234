@import '../../assets/sass/styles.scss';
.Main {
  background-color: white;
  .prizes {
    text-align: center;
    padding: 3rem 0;
    img {
      max-height: 40vh;
    }
  }
  .footer {
    background-color: white;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-height: 70vh;
    }
    .footer-text {
      position: absolute;
      margin-left: 45%;
      margin-bottom: 20%;
      span {
        display: block;
        color: $accent;
        font-weight: 600;
        font-size: 3rem;
        line-height: 1.1;
      }
    }
  }
}

@media screen and (max-width: 769px) {
  .Main {
    .prizes {
      padding: 2rem;
    }
    .footer {
      .footer-text {
        margin-left: 69%;
        margin-bottom: 47%;
        span {
          font-size: 1.3rem;
        }
      }
    }
  }
}
