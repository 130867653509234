@import '../../assets/sass/styles.scss';

.Header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .logo-container {
    padding: 100px;
    img {
      max-width: 150px;
      position: absolute;
      right: 40px;
      top: 25px;
    }
  }
  .banner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .banner-image {
      max-height: 70vh;
    }
    .buy-win {
      .column {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        span {
          font-weight: 600;
          font-size: 4rem;
        }
        img {
          height: 160px;
        }
      }
    }
  }
  .description-container {
    .campaign-description {
      font-size: 3rem;
      font-weight: 600;
      color: $primary;
      margin: 60px 0 40px 0;
      line-height: 1;
      text-align: center;
      //   max-width: 60%;
    }
    .campaign-period {
      text-align: center;
      font-size: 1.6rem;
      font-weight: 600;
      span {
        display: block;
      }
    }
  }
  .nav-container {
    margin: 20px auto;
    .button {
      margin: 10px;
    }
  }
}

@media screen and (max-width: 769px) {
  .Header {
    .logo-container {
      padding: 20px;
      img {
        position: relative;
        right: 0px;
        top: 0px;
      }
    }
    .banner-container {
      .banner-image {
        align-items: flex-start;
        background-size: cover;
        justify-content: center;
        h2 {
          font-size: 1.5rem;
          font-weight: 500;
          padding: 1rem 0;
          max-width: 100%;
        }
      }
      .buy-win {
        .column {
          padding: 0;
          span {
            font-size: 2.5rem;
          }
          img {
            height: 75px;
          }
        }
      }
    }
    .description-container {
      .campaign-description {
        font-size: 1.4rem;
        padding: 0 20px;
      }
      .campaign-period {
        font-size: 1rem;
      }
    }
    .nav-container {
      margin: 20px auto;
      text-align: center;
      .button {
        width: 90vw;
      }
    }
  }
}
